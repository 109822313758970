<!--

© 2020 – 2021 ProCSy JSC https://procsy.ru info@procsy.ru

© АО «ПроКСи», 2020 – 2021 info@procsy.ru

-->

<template>
  <CRow>
    <CCol>
      <CForm>
        <CCard>
          <CCardHeader>
            <strong>{{$t('network.creatingNetwork')}}</strong>
          </CCardHeader>
          <CCardBody>
            <template>
              <CInput
                :label="$t('common.title')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-model="network.name"
                :isValid="isValid.name"
                :tooltipFeedback=false
                :invalidFeedback="validationMessages.required"
                @change="$v.network.name.$touch()"
              />
              <CInput
                :label="$t('network.programId')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-model="network.codeName"
                :isValid="isValid.codeName"
                :tooltipFeedback=false
                :invalidFeedback="validationMessages.required"
                @change="$v.network.codeName.$touch()"
              />

              <CSelect
                :label="$t('network.myRole')"
                :options="[
                  { value: orgTypes.orderer_organizations, label: 'OSN' },
                ]"
                horizontal
                disabled
                required
                :placeholder="$t('network.selectRole')"
                addLabelClasses="font-weight-bold"
                :value.sync="network.orgRole"
                :isValid="isValid.orgRole"
                :tooltipFeedback=false
                :invalidFeedback="validationMessages.required"
                @change="$v.network.orgRole.$touch()"
              />

              <CInput
                :label="$t('network.myOrganizationName')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-model="network.myOrganizationName"
                :isValid="isValid.myOrganizationName"
                :tooltipFeedback=false
                :invalidFeedback="validationMessages.required"
                @change="$v.network.myOrganizationName.$touch()"
              />

              <CInput
                :label="$t('network.mspidMy')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-model="network.orgMspid"
                :isValid="isValid.orgMspid"
                :tooltipFeedback=false
                :invalidFeedback="validationMessages.required"
                @change="$v.network.orgMspid.$touch()"
              />

              <CInput
                :label="$t('network.myOrgDomain')"
                horizontal
                addLabelClasses="font-weight-bold"
                v-model="network.domain"
                :isValid="isValid.domain"
                :tooltipFeedback=false
                :invalidFeedback="errorMessageForDomain"
                @change="$v.network.domain.$touch()"
              />

              <CRow form class="form-group">
                <CCol sm="3" class="font-weight-bold">
                  {{$t('network.platform')}}
                </CCol>
                <CInputRadioGroup
                  class="col-sm-9"
                  :options="network_stacks"
                  :checked.sync="network_stack"
                  :inline="true"
                  :custom="true"
                  :disabled="true"
                  required
                />
              </CRow>
              <CRow form class="form-group" v-if="network_stack">
                <CCol sm="3" class="font-weight-bold">
                  {{$t('network.type')}}
                </CCol>
                <CCol sm="9">
                  <template>
                    <div v-for="(crypto, i) in networkTypes" class="float-left mr-5">
                      <strong class="mb-1 d-block">{{ crypto.label }}</strong>
                      <CInputRadioGroup
                        :options="crypto.types"
                        :checked.sync="network.type"
                        :custom="true"
                        value="hlf"
                        :isValid="isValid.type"
                        :tooltipFeedback=false
                        :invalidFeedback="validationMessages.required"
                        @change="$v.network.type.$touch()"
                      />
                    </div>
                  </template>
                </CCol>
              </CRow>

              <CSelect
                v-if="network_stack === 'hlf'"
                :label="$t('network.netPolicy')"
                :options="[
                  { value: policy.EXCLUSIVE.code, label: policy.EXCLUSIVE.text },
                  { value: policy.OR.code, label: policy.OR.text},
                  { value: policy.MAJORITY.code, label: policy.MAJORITY.text}
                ]"
                horizontal
                required
                :placeholder="$t('network.selectPolicy')"
                addLabelClasses="font-weight-bold"
                :value.sync="network.policy"
                :isValid="isValid.policy"
                :tooltipFeedback=false
                :invalidFeedback="validationMessages.required"
                @change="$v.network.policy.$touch()"
              />
            </template>


          </CCardBody>
          <CCardFooter>
            <CButton
              color="primary"
              :disabled="!canCreate"
              @click.prevent.default="createNetwork()"
            >{{ $t('common.next') }} <fa-icon icon="angle-double-right" size="sm" /></CButton>
          </CCardFooter>
        </CCard>
      </CForm>
    </CCol>
  </CRow>
</template>

<script>

import { required } from 'vuelidate/lib/validators'

import utilMessages from '@/utils/messages'
import translate from '@/utils/translate'
import { isValidDomain } from '@/utils/validators'

import FormObjectsCollapse from "../components/FormObjectsCollapse.vue"
import messages from '../messages'

import { mapGetters } from "vuex"
import {
BlockchainConfigUpdateType,
NetworkOrgTypes,
NetworkTypes
} from "./constants"

export default {
  name: 'NetworkCreate',
  components: {
    FormObjectsCollapse
  },
  data () {
    return {
      validationMessages: messages.validation,
      network: {
        name: null,
        codeName: null,
        type: null,
        policy: null,
        orgRole: null,
        orgMspid: null,
        domain: null,
        myOrganizationName: null
      },

      network_status: null,
      network_stack: 'hlf',
      network_stacks: [
        { value: 'hlf', label: 'Hyperledger Fabric'},
        { value: 'corda', label: 'Corda', props:{disabled:true}},
        { value: 'eth', label: 'Ethereum', props:{disabled:true}}
      ],
      policy: BlockchainConfigUpdateType,
      netTypes: NetworkTypes,
      orgTypes: NetworkOrgTypes,
      translate: translate,
    }
  },
  validations: {
    network: {
      name: {
        required
      },
      codeName: {
        required
      },
      type: {
        required
      },
      policy: {
        required
      },
      orgRole: {
        required
      },
      myOrganizationName: {
        required
      },
      orgMspid: {
        required
      },
      domain: {
        required,
        isValidDomain,
      },
    }
  },
  mounted () {
    this.initDefault()
  },
  watch: {
    'network.name' (newName, oldName) {
      this.network.codeName = translate.toCamelCase(translate.rusToLatin(newName))
    }
  },
  computed: {
    ...mapGetters(['getMsp']),
    isValid() {
      let name, codeName, type, policy, orgRole, orgMspid, domain, myOrganizationName;
      if (this.$v.network.name.$dirty)
        name = !this.$v.network.name.$invalid

      if (this.$v.network.codeName.$dirty)
        codeName = !this.$v.network.codeName.$invalid

      if (this.$v.network.type.$dirty)
        type = !this.$v.network.type.$invalid

      if (this.$v.network.policy.$dirty)
        policy = !this.$v.network.policy.$invalid

      if (this.$v.network.orgRole.$dirty)
        orgRole = !this.$v.network.orgRole.$invalid

      if (this.$v.network.orgMspid.$dirty)
        orgMspid = !this.$v.network.orgMspid.$invalid

      if (this.$v.network.domain.$dirty)
        domain = !this.$v.network.domain.$invalid

      if (this.$v.network.myOrganizationName.$dirty) {
        myOrganizationName = !this.$v.network.myOrganizationName.$invalid
      }

      return {
        name: name,
        codeName: codeName,
        type: type,
        policy: policy,
        orgRole: orgRole,
        orgMspid: orgMspid,
        domain: domain,
        myOrganizationName
      }
    },
    errorMessageForDomain() {
      if (!this.$v.network.domain.required) {
        return this.validationMessages.required
      } else if (!this.$v.network.domain.isValidDomain) {
        return this.validationMessages.domain
      }
      return null
    },
    canCreate() {
      return !this.$v.network.$invalid
    },
    networkTypeLabel(){
      switch (this.network.type){
        case this.netTypes.HLF14.code:
          return this.netTypes.HLF14.text;
        case this.netTypes.HLF20.code:
          return this.netTypes.HLF20.text;
        case this.netTypes.HLF22.code:
          return this.netTypes.HLF22.text;
        case this.netTypes.GOSMART10.code:
          return this.netTypes.GOSMART10.text;
      }
    },
    networkTypes(){
      switch (this.network_stack){
        case 'hlf':
          return [
              {
                label: "ECDSA",
                types: [
                  { value: this.netTypes.HLF22.code, label: this.netTypes.HLF22.text},
                ]
              },
              {
                label: this.$i18n.t('network.gost'),
                types: [
                  { value: this.netTypes.GOSMART10.code, label: this.netTypes.GOSMART10.text, props:{ disabled: true}}
                ]
              },
          ];
        default:
          return [];
      }
    }
  },
  methods: {
    initDefault () {
      this.network.orgRole = this.orgTypes.orderer_organizations
      this.network.type = this.netTypes.HLF22.code

      this.$v.network.orgRole.$touch()
      this.$v.network.type.$touch()
    },
    async createNetwork () {
      // creating of network contains 5 stages
      // 1 stage: fetch user msp
      try {
        await this.$store.dispatch('fetchMsp')
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
        return
      }

      // 2 stage: creating of organization
      const orgData = {
        msp: this.getMsp,
        mspid: this.network.orgMspid,
        domain: this.network.domain,
        name: this.network.myOrganizationName
      }

      let org;
      try {
        org = await this.$store.dispatch('createOrganization', orgData)
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
        return
      }

      // 3 stage: creating of network
      const networkData = {
        name: this.network.name,
        code_name: this.network.codeName,
        blockchain_update_policy_type: this.network.policy,
        version: this.network.type,
      }

      let network
      try {
        network = await this.$store.dispatch('createNetwork', networkData)
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
        return
      }

      // 4 stage: adding org to network
      network[this.network.orgRole].push(org)

      const draftData = {
        netId: network.id,
        data: network,
        withoutCheck: true,
      }
      try {
        const res = await this.$store.dispatch('saveDraftNetwork', draftData)
      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
        return
      }

      // 5 stage: switch organization
      try {
        await this.$store.dispatch('switchBlockchain', network.id)
        this.$router.push({path: `/networks/${network.id}/draft`})

      } catch (err) {
        this.$toast.error(utilMessages.errMessage(err))
      }


    },
    getBadge (status) {
      switch (status) {
        case 'active': return 'success'
        case 'draft': return 'primary'
      }
    }
  }
}
</script>
